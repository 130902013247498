import React, { useState, useEffect } from "react";
import briscalogo from '../img/briscalogo.png';
import chinchonlogo from '../img/chinchonlogo.png';
import sopalogo from '../img/sopalogo.png';
import solitarioslogo from '../img/solitarioslogo.png';
import Rating from '@mui/material/Rating';
import { Card, CardContent, CardMedia, Typography, Modal, Backdrop, Fade, Button, IconButton } from '@mui/material';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import GooglePlayIcon from '@mui/icons-material/Shop';
import AppStoreIcon from '@mui/icons-material/Apple';
import Aos from 'aos';
import Box from '@mui/material/Box';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';

const Destacados = () => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);

    useEffect(() => {
        Aos.init();
    }, []);

    const handleOpen = (card) => {
        setSelectedCard(card);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const cardsData = [
        {
            title: t('la_brisca_title'),
            description: t('la_brisca_description'),
            descripcionLarga: t('la_brisca_descripcion_larga'),
            image: briscalogo,
            linkgp: "https://play.google.com/store/apps/details?id=com.quarzo.brisca&pli=1",
            linkas: "https://apps.apple.com/es/app/la-brisca-versi%C3%B3n-espa%C3%B1ola/id1564308080",
            valoracion: 4.5
        },
        {
            title: t('el_chinchon_title'),
            description: t('el_chinchon_description'),
            descripcionLarga: t('el_chinchon_descripcion_larga'),
            image: chinchonlogo,
            linkgp: "https://play.google.com/store/apps/details?id=com.quarzo.chinchon&hl=es&gl=US",
            linkas: "https://apps.apple.com/es/app/chinchon-cards/id1624268816",
            valoracion: 4.5
        },
        {
            title: t('sopa_de_letras_title'),
            description: t('sopa_de_letras_description'),
            descripcionLarga: t('sopa_de_letras_descripcion_larga'),
            image: sopalogo,
            linkgp: "https://play.google.com/store/apps/details?id=com.jdpapps.wordsearch&hl=es&gl=US",
            valoracion: 5
        },
        {
            title: t('solitarios_title'),
            description: t('solitarios_description'),
            descripcionLarga: t('solitarios_descripcion_larga'),
            image: solitarioslogo,
            linkgp: "https://play.google.com/store/apps/details?id=com.quarzo.solitarios&hl=es&gl=US",
            valoracion: 4.5
        }
    ];

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        maxWidth: '600px',
        height: '80%',
        maxHeight: '800px',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        borderRadius: '10px',
        backgroundColor: '#08171D',
        color: 'white',
        overflowY: 'auto',
    };

    const closeButtonStyle = {
        position: 'absolute',
        right: '10px',
        top: '10px',
        color: 'white',
    };

    const contentStyle = {
        mt: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    };

    return (
        <Grid container direction="column" spacing={0} className="destacados">
            <div className="aparecer">
                <Typography variant="h2" component="div" align="center" sx={{
                        fontFamily: "'Bebas Neue', sans-serif", color: 'white', marginTop: 10, marginBottom: 0, textTransform: 'uppercase', letterSpacing: 2, textShadow: '2px 2px 8px rgba(0, 0, 0, 0.5)', fontSize: {     xs: '3rem', sm: '3.5rem', md: '4rem', lg: '4.5rem' },}}>
                    {t("destacados1")}
                </Typography>
                <Divider sx={{backgroundColor: 'white', height: 2, width: {xs: '90%', sm: '80%', md: '65%', lg: '75%', }, margin: '0 auto 20px'}}/>
                {cardsData.map((card, index) => (
                    <Grid
                        item
                        key={index}
                        data-aos={index % 2 === 0 ? "fade-up" : "fade-up"}
                        className="tarjeta-container"
                        sx={{ mb: 3, p: 0, border: 'none' }}
                    >
                        <Card
                            className="tarjetadestacados"
                            style={{ backgroundColor: '#08171D', margin: 0, border: 'none', boxShadow: 'none' }}
                            onClick={() => handleOpen(card)}
                        >
                            <div style={{ display: 'flex', flexDirection: index % 2 === 0 ? 'row' : 'row-reverse', alignItems: 'center' }}>
                                <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <CardMedia
                                        component="img"
                                        height="auto"
                                        style={{ maxWidth: '100%', height: 'auto', objectFit: 'contain', maxHeight: '200px', margin: '10px' }}
                                        image={card.image}
                                        alt={`Imagen ${index}`}
                                    />
                                </div>
                                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                    <CardContent >
                                        <Typography data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="500" data-aos-offset="0" gutterBottom variant="h4" component="div" align="center" style={{ fontFamily: "'Bebas Neue', sans-serif", color: 'white' }}>
                                            {card.title}
                                        </Typography>
                                        <Typography data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="1000" data-aos-offset="0" variant="body2" color="text.secondary" align="center" style={{ fontFamily: "'Open Sans', sans-serif", color: 'white' }}>
                                            {card.description}
                                        </Typography>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Rating data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="1000" data-aos-offset="0" name="read-only" value={card.valoracion} readOnly precision={0.5} />
                                        </div>
                                    </CardContent>
                                </div>
                            </div>
                        </Card>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 1000,
                                style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' },
                            }}
                        >
                            <Fade in={open}>
                                <Box sx={modalStyle}>
                                    <IconButton sx={closeButtonStyle} onClick={handleClose}>
                                        <CloseIcon sx={{ color: 'white' }} />
                                    </IconButton>
                                    {selectedCard && (
                                        <Box sx={contentStyle}>
                                            <Typography
                                                id="modal-title"
                                                variant="h4"
                                                fontFamily="'Bebas Neue', sans-serif"
                                                component="h2"
                                                align="center"
                                            >
                                                {selectedCard.title}
                                            </Typography>
                                            <Box display="flex" justifyContent="center" my={2}>
                                                <img
                                                    src={selectedCard.image}
                                                    alt={selectedCard.title}
                                                    style={{
                                                        width: '100%',
                                                        height: 'auto',
                                                        maxWidth: '300px',
                                                    }}
                                                />
                                            </Box>
                                            <Typography
                                                id="modal-description"
                                                fontFamily="'Open Sans', sans-serif"
                                                sx={{ mt: 2 }}
                                                align="center"
                                            >
                                                {selectedCard.descripcionLarga}
                                            </Typography>
                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                flexWrap="wrap"
                                                mt={2}
                                            >
                                                {selectedCard.linkgp && (
                                                    <Typography sx={{ m: 1 }}>
                                                        <Button
                                                            variant="contained"
                                                            sx={{
                                                                backgroundColor: 'white',
                                                                color: 'black',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                            }}
                                                            href={selectedCard.linkgp}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <GooglePlayIcon /> Google Play
                                                        </Button>
                                                    </Typography>
                                                )}
                                                {selectedCard.linkas && (
                                                    <Typography sx={{ m: 1 }}>
                                                        <Button
                                                            variant="contained"
                                                            sx={{
                                                                backgroundColor: 'white',
                                                                color: 'black',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                            }}
                                                            href={selectedCard.linkas}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <AppStoreIcon /> App Store
                                                        </Button>
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                            </Fade>
                        </Modal>
                    </Grid>
                ))}
            </div>
        </Grid>
    );
}

export default Destacados;
