import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const Selectoridioma = () => {
    const { i18n } = useTranslation();
    const [language, setLanguage] = React.useState(i18n.language || 'es');

    const handleLanguageChange = (e) => {
        const newLanguage = e.target.value;
        setLanguage(newLanguage);
        i18n.changeLanguage(newLanguage);
    };

    useEffect(() => {
        if (i18n.language !== language) {
            setLanguage(i18n.language);
        }
    }, [i18n.language, language]);

    const theme = createTheme({
        components: {
            MuiSelect: {
                styleOverrides: {
                    select: {
                        color: 'white', 
                    },
                    icon: {
                        color: 'white', 
                    },
                },
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ minWidth: 50 }}>
                <FormControl>
                    <Select
                        value={language}
                        onChange={handleLanguageChange}
                        sx={{ '.MuiOutlinedInput-notchedOutline': { borderStyle: 'none' } }}
                    >
                        <MenuItem value="es">
                            ES
                        </MenuItem>
                        <MenuItem value="en">
                            EN
                        </MenuItem>
                    </Select>
                </FormControl>
            </Box>
        </ThemeProvider>
    );
};

export default Selectoridioma;
