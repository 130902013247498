import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import EngineeringIcon from '@mui/icons-material/Engineering';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Clasificaciones = () => {
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                backgroundColor: "#0A1A1B",
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: 0,
                margin: 0, 
            }}
        >
            <Container>
                <Typography
                    variant="h2"
                    component="div"
                    align="center"
                    sx={{
                        fontFamily: "'Bebas Neue', sans-serif",
                        color: 'white',
                        marginTop: 10,
                        marginBottom: 0,
                        textTransform: 'uppercase',
                        letterSpacing: 2,
                        textShadow: '2px 2px 8px rgba(0, 0, 0, 0.5)',
                        fontSize: {
                            xs: '3rem',
                            sm: '3.5rem',
                            md: '4rem',
                            lg: '4.5rem'
                        },
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <EngineeringIcon sx={{ marginRight: 2 }} />
                    {t("rankings1")}
                    <EngineeringIcon sx={{ marginLeft: 2 }} />
                </Typography>
                <Divider sx={{ backgroundColor: 'white', marginY: 2 }} />
                <Typography
                    variant="body1"
                    align="center"
                    sx={{
                        color: 'white',
                        marginTop: 2,
                        marginBottom: 4,
                        fontSize: {
                            xs: '1rem',
                            sm: '1.2rem',
                            md: '1.3rem',
                            lg: '1.4rem'
                        },
                    }}
                >
                    {t("rankings2")}
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <Button
                        component={Link}
                        to="/"
                        variant="outlined"
                        sx={{
                            marginTop: '20px',
                            marginBottom: '20px',
                            color: 'white',
                            textTransform: 'uppercase',
                            fontFamily: "'Bebas Neue', sans-serif",
                            fontSize: {
                                xs: '1.5rem',
                                sm: '1.75rem',
                                md: '2rem',
                                lg: '2.25rem'
                            },
                            padding: {
                                xs: '10px 30px',
                                sm: '12px 36px',
                                md: '14px 42px',
                                lg: '16px 48px'
                            },
                            borderColor: 'white',
                            '&:hover': {
                                color: '#232B7A',
                                borderColor: '#232B7A',
                            },
                        }}
                    >
                        {t("rankings3")}
                    </Button>
                </Box>
            </Container>
        </Box>
    );
};

export default Clasificaciones;