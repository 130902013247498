import React, { useEffect } from "react";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import EmailIcon from '@mui/icons-material/Email';
import TwitterIcon from '@mui/icons-material/Twitter';
import Box from '@mui/material/Box';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';
import logo from '../img/logo.png'; 

const About = () => {
    const { t } = useTranslation();

    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    const renderTextWithLineBreaks = (text) => {
        return text.split('\n').map((line, index) => (
            <Typography
                key={index}
                variant="body1"
                align="center"
                paragraph
                sx={{ fontSize: { xs: '1rem', md: '1.25rem', lg: '1.5rem' }, color: 'white', marginTop: index === 0 ? '80px' : '0' }}
                data-aos="fade-up"
                data-aos-duration="1000"
            >
                {line}
            </Typography>
        ));
    };

    return (
        <div style={{ backgroundColor: '#08171D', padding: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
            <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ maxWidth: '1200px' }}>
                <Grid item xs={12}>
                    <Typography
                        variant="h1"
                        align="center"
                        gutterBottom
                        sx={{ fontSize: { xs: '3rem', md: '4rem', lg: '5rem' },fontFamily: "'Bebas Neue', sans-serif",letterSpacing: 2, textShadow: '2px 2px 8px rgba(0, 0, 0, 0.5)', fontWeight: 'bold', color: '#8FC1DF', marginTop: '100px' }}
                        data-aos="fade-up"
                    >
                        {t('about1')}
                    </Typography>
                    <Divider variant="middle" sx={{ backgroundColor: '#8FC1DF', height: 2, borderWidth: 2, marginY: '30px' }} />
                    {renderTextWithLineBreaks(t('about2'))}
                </Grid>
                <Grid item xs={12}>
                    <Box 
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '50px',
                            marginBottom: '50px',
                        }}
                        data-aos="fade-up"
                    >
                        <img 
                            src={logo} 
                            alt="Descripción de la imagen" 
                            style={{ 
                                maxWidth: '100%', 
                                height: 'auto',
                                borderRadius: '10px'
                            }} 
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        variant="h1"
                        align="center"
                        gutterBottom
                        sx={{ fontSize: { xs: '3rem', md: '4rem', lg: '5rem' }, fontWeight: 'bold', color: '#8FC1DF', marginTop: '70px' }}
                        data-aos="fade-up"
                    >
                        {t('contact1')}
                    </Typography>
                    <Divider variant="middle" data-aos="fade-up" sx={{ backgroundColor: '#8FC1DF', height: 2, borderWidth: 2, marginY: '30px' }} />
                    <Typography
                        variant="body1"
                        align="center"
                        sx={{ fontSize: { xs: '1rem', md: '1.25rem', lg: '1.5rem' }, color: 'white', marginTop: '50px', marginBottom: '50px' }}
                        data-aos="fade-up"
                        data-aos-duration="1000"
                    >
                        <EmailIcon style={{ verticalAlign: 'middle' }} />
                        <span style={{ verticalAlign: 'middle' }}> {t('contact2')} </span>
                        <EmailIcon style={{ verticalAlign: 'middle' }} /> <br />
                        <a href="mailto:hola@quarzoapps.com" style={{ color: 'white', textDecoration: 'none' }}>hola@quarzoapps.com</a><br /><br />
                        <TwitterIcon style={{ verticalAlign: 'middle' }} />
                        <span style={{ verticalAlign: 'middle' }}> {t('contact3')} </span>
                        <TwitterIcon style={{ verticalAlign: 'middle' }} /> <br />
                        <a href="https://twitter.com/quarzoapps" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>@quarzoapps</a>
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
}

export default About;
