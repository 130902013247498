import React from 'react';
import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Destacados2 = () => {
    const { t } = useTranslation();

    return (
        <div style={{ textAlign: 'center', marginTop: '0px', backgroundColor: '#08171D', padding: '20px' }}>
            <Button
                component={Link}
                to="/games"
                variant="outlined"
                sx={{
                    marginTop: '20px',
                    marginBottom: '20px',
                    color: 'white',
                    textTransform: 'uppercase',
                    fontFamily: "'Bebas Neue', sans-serif",
                    fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2rem', lg: '2.25rem' },
                    padding: { xs: '10px 30px', sm: '12px 36px', md: '14px 42px', lg: '16px 48px' },
                    borderColor: 'white',
                    '&:hover': {
                        color: '#232B7A',
                        borderColor: '#232B7A',
                    },
                }}
            >
                {t("ir_todos_juegos")}
            </Button>
        </div>
    );
};

export default Destacados2;
