import React from "react";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { List, ListItem, Link } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Typography from '@mui/material/Typography';

const PrivacidadEs = () => {
    return (
        <div style={{ backgroundColor: '#08171D', padding: '20px'}}>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12} md={10} lg={8} sx={{ paddingX: 3 }}>
                    <Typography variant="h1" align="center" gutterBottom sx={{ fontSize: { xs: '2.5rem', md: '3.5rem', lg: '4rem' }, fontWeight: 'bold', color: '#fff', marginTop: '30px' }} >
                        Quarzo Apps <br /> Politica de privacidad
                        <Grid item xs={12}>
                            <Divider variant="middle" sx={{ backgroundColor: '#232B7A', height: 2, borderWidth: 2, marginTop: '15px' }} />
                        </Grid>
                    </Typography>
                    <Typography variant="body1" align="center" paragraph sx={{ fontSize: { xs: '1rem', md: '1.25rem', lg: '1.5rem' }, color: '#fff', marginTop: '30px' }} >
                        "Quarzo Aplicaciones y Juegos SL" en adelante "Quarzo Apps" utiliza identificadores de dispositivo anónimos en nuestros juegos y apps para que podamos mejorarlas y para dar al usuario la mejor experiencia de juego posible. Este recoge datos anónimos que simplemente nos permite descubrir lo que los usuarios prefieren y que no les gusta. Quarzo Apps respeta plenamente la privacidad del usuario y nunca recopila información que podría ser directamente relacionado con el usuario. Quarzo Apps también muestra publicidad para financiar la creación de estos juegos. Todo esto se rige por la siguiente política de privacidad:

                        <br /><br />TENGA EN CUENTA QUE AL INSTALAR, USAR O ACCESO A CUALQUIER APLICACIÓN DE QUARZO APPS, USTED ACEPTA ESTA POLÍTICA DE PRIVACIDAD. SI USTED NO ESTÁ DE ACUERDO CON ESTA POLÍTICA DE PRIVACIDAD, POR FAVOR NO INSTALE, USO O DE OTRO ACCESO nuestras aplicaciones. Nos reservamos el derecho de modificar esta Política de Privacidad en horas razonables, así que por favor revise con frecuencia. El uso continuado de nuestras aplicaciones significará su aceptación de los cambios a esta Política de Privacidad.
                    </Typography>
                    <Typography variant="h1" align="center" gutterBottom sx={{ fontSize: { xs: '2.5rem', md: '3.5rem', lg: '4rem' }, fontWeight: 'bold', color: '#fff', marginTop: '30px' }} >
                        Cuentas Online e Información personal
                        <Grid item xs={12}>
                            <Divider variant="middle" sx={{ backgroundColor: '#232B7A', height: 2, borderWidth: 2, marginTop: '15px' }} />
                        </Grid>
                    </Typography>
                    <Typography variant="body1" align="center" paragraph sx={{ fontSize: { xs: '1rem', md: '1.25rem', lg: '1.5rem' }, color: '#fff', marginTop: '30px' }} >
                        Algunas aplicaciones o juegos pueden tener que registrar una cuenta para jugar. Necesitarán apodo, contraseña, imagen de avatar, correo electrónico, país u otra información personal que se preste. No vamos a vender, alquilar o transferir su información personal a terceros sin su consentimiento previo.
                        <br /><br />La dirección de correo electrónico nunca será pública a otros usuarios. Sólo será utilizada para recuperar una cuenta de "perdida" mediante el envío de una nueva contraseña, o, a veces ponerse en contacto o informar a los usuarios acerca de los avisos importantes.
                    </Typography>
                    <Typography variant="h1" align="center" gutterBottom sx={{ fontSize: { xs: '2.5rem', md: '3.5rem', lg: '4rem' }, fontWeight: 'bold', color: '#fff', marginTop: '30px' }} >
                        Anuncios
                        <Grid item xs={12}>
                            <Divider variant="middle" sx={{ backgroundColor: '#232B7A', height: 2, borderWidth: 2, marginTop: '15px' }} />
                        </Grid>
                    </Typography>
                    <Typography variant="body1" align="center" paragraph sx={{ fontSize: { xs: '1rem', md: '1.25rem', lg: '1.5rem' }, color: '#fff', marginTop: '30px' }} >
                        Algunas de nuestras Apps y Juegos muestran anuncios. Estos son necesarios para mantener el desarrollo de nuestra empresa. Se puede realizar una compra que retira dichos anuncios para siempre. Para más información sobre los anuncios y sobre los anuncios personalizados consulte este enlace: <a href="https://support.google.com/My-Ad-Center-Help/answer/12155764?hl=es&visit_id=638505857661831699-2681632183&rd=1" target="_blank" rel="noopener noreferrer">Soporte de Google a los anuncios</a>
                    </Typography>
                    <Typography variant="h1" align="center" gutterBottom sx={{ fontSize: { xs: '2.5rem', md: '3.5rem', lg: '4rem' }, fontWeight: 'bold', color: '#fff', marginTop: '30px' }} >
                        Datos anónimos
                        <Grid item xs={12}>
                            <Divider variant="middle" sx={{ backgroundColor: '#232B7A', height: 2, borderWidth: 2, marginTop: '15px' }} />
                        </Grid>
                    </Typography>
                    <Typography variant="body1" align="center" paragraph sx={{ fontSize: { xs: '1rem', md: '1.25rem', lg: '1.5rem' }, color: '#fff', marginTop: '30px' }} >
                        Los anunciantes que proporcionan anuncios a nuestras aplicaciones móviles pueden acceder a su identificador de dispositivo único y usar esto para dirigir los mensajes a usted. <br /><br />Quarzo Apps puede recopilar y utilizar datos anónimos. No podemos identificar a partir de estos datos. <br /><br />Quarzo Apps puede usar los datos recogidos con el fin de analizar cómo se utilizan nuestras aplicaciones, y utilizar la información para desarrollar y gestionar nuestras aplicaciones. Quarzo Apps se reserva el derecho a utilizar la información anónima recopilada nosotros mismos o revelarla a nuestros socios. <br /><br />Quarzo Apps puede utilizar el servicio proporcionado por Google Analytics y/o Google Firebase. Son servicios de análisis de Google Inc. ("Google"). Google utiliza los datos recogidos para rastrear y analizar el uso de esta aplicación, para preparar informes sobre sus actividades y compartirlas con otros servicios de Google. Google podrá utilizar los datos recogidos para contextualizar y personalizar los anuncios de su propia red de publicidad. Los datos personales recogidos: datos de las cookies y el uso.
                    </Typography>
                    <Typography variant="h1" align="center" gutterBottom sx={{ fontSize: { xs: '2.5rem', md: '3.5rem', lg: '4rem' }, fontWeight: 'bold', color: '#fff', marginTop: '30px' }} >
                        Terceras partes y Condiciones
                        <Grid item xs={12}>
                            <Divider variant="middle" sx={{ backgroundColor: '#232B7A', height: 2, borderWidth: 2, marginTop: '15px' }} />
                        </Grid>
                    </Typography>
                    <Typography variant="body1" align="center" paragraph sx={{ fontSize: { xs: '1rem', md: '1.25rem', lg: '1.5rem' }, color: '#fff', marginTop: '30px' }} >
                        Tenga en cuenta que ciertos terceros, incluyendo pero no limitado a las tiendas de aplicaciones, puede ser necesario para que usted tenga acceso a nuestras aplicaciones móviles. Estos terceros tienen sus propias políticas de privacidad que Quarzo Apps no puede controlar. Usted acepta que Quarzo Apps no se hace responsable por el uso de su información personal.
                    </Typography>
                    <Typography variant="h1" align="center" gutterBottom sx={{ fontSize: { xs: '2.5rem', md: '3.5rem', lg: '4rem' }, fontWeight: 'bold', color: '#fff', marginTop: '30px' }} >
                        Enlaces a sitios web de terceras partes o juegos
                        <Grid item xs={12}>
                            <Divider variant="middle" sx={{ backgroundColor: '#232B7A', height: 2, borderWidth: 2, marginTop: '15px' }} />
                        </Grid>
                    </Typography>
                    <Typography variant="body1" align="center" paragraph sx={{ fontSize: { xs: '1rem', md: '1.25rem', lg: '1.5rem' }, color: '#fff', marginTop: '30px' }}>
                        Nuestras aplicaciones móviles son soportadas por la publicidad, que permiten acceder a sitios de terceros o juegos.<br /><br />
                        Nuestras aplicaciones móviles proporcionan acceso a las redes sociales, la comunicación y las funciones de búsqueda.<br /><br />
                        Tenga en cuenta que, si utiliza cualquiera de estos enlaces saldrá de nuestra aplicación y la página de ir a no se regirá por nuestra política de privacidad. Nosotros no podemos ser responsables de la privacidad de cualquier información que usted proporcione al exterior de nuestra aplicación.<br /><br />
                        Actualmente trabajamos con las siguientes compañías proveedoras de anuncios y de análisis de datos:
                    </Typography>

                    <Container maxWidth="lg" sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                        <Box textAlign="center">
                            <List sx={{ listStyleType: 'disc', textAlign: 'center', padding: 0, fontSize: { xs: '1rem', md: '1.25rem', lg: '1.5rem' }, color: '#fff' }}>
                                <ListItem>
                                    - Google AdMob (<Link href="https://policies.google.com/technologies/partner-sites">Política de privacidad</Link>)
                                </ListItem>
                                <ListItem>
                                    - Google Analytics (<Link href="https://support.google.com/analytics/answer/7318509?hl=en/">Política de privacidad</Link>)
                                </ListItem>
                                <ListItem>
                                    - Chartboost (<Link href="https://docs.chartboost.com/en/legal/privacy-policy/">Política de privacidad</Link>)
                                </ListItem>
                                <ListItem>
                                    - Applovin (<Link href="https://www.applovin.com/privacy/">Política de privacidad</Link>)
                                </ListItem>
                                <ListItem>
                                    - Unity Ads (<Link href="https://unity.com/es/legal/privacy-policy">Política de privacidad</Link>)
                                </ListItem>
                                <ListItem>
                                    - Facebook Audience Network (<Link href="https://transparency.meta.com/es-es/policies/ad-standards/?source=https%3A%2F%2Fwww.facebook.com%2Fpolicies_center%2Fads">Política de privacidad</Link>)
                                </ListItem>
                                <ListItem>
                                    - Firebase (<Link href="https://firebase.google.com/support/privacy?hl=es">Política de privacidad</Link>)
                                </ListItem>
                            </List>
                        </Box>
                    </Container>
                    <Typography variant="body1" align="center" paragraph sx={{ fontSize: { xs: '1rem', md: '1.25rem', lg: '1.5rem' }, color: '#fff', marginTop: '30px' }}>
                        Por favor, referirse a las políticas de privacidad de dichas compañías para obtener información relativa a sus políticas de privacidad.
                    </Typography>
                    <Typography variant="body1" align="center" paragraph sx={{ fontSize: { xs: '1rem', md: '1.25rem', lg: '1.5rem' }, fontWeight: 'bold', color: '#fff', marginTop: '30px' }}>
                        Versión 1.0 - Documento creado el 23 Ene. 2015.<br />
                        Versión 1.1 - Documento actualizado el 29 Sep. 2015.<br />
                        Versión 1.2 - Documento actualizado el 06 Abr. 2017.<br />
                        Versión 1.3 - Documento actualizado el 15 Mar. 2018.<br />
                        Versión 1.4 - Documento actualizado el 21 Jun. 2018.<br />
                        Versión 1.5 - Documento actualizado el 06 May. 2024 (incluye cambios GDPR).
                    </Typography>
                    <Typography variant="body1" align="center" paragraph sx={{ fontSize: { xs: '1rem', md: '1.25rem', lg: '1.5rem' }, fontWeight: 'bold', color: '#fff', marginTop: '30px' }}>
                        © Quarzo Apps<br />
                        Quarzo Aplicaciones y Juegos SL<br />
                        CIF: B87302808<br />
                        Jupiter, 16<br />
                        28229 Madrid<br />
                        SPAIN<br /><br />
                        Contacto: hola@quarzoapps.com | @quarzoapps
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
}

export default PrivacidadEs;