import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Rating from "@mui/material/Rating";
import CountUp from "react-countup";
import googleplaypass from "../img/googleplaypass.png";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import AndroidIcon from "@mui/icons-material/Android";
import AppleIcon from "@mui/icons-material/Apple";

const formatNumber = (number) => {
    if (number >= 1000000) {
        return '+' + (number / 1000000).toFixed(1) + 'M de descargas';
    } else if (number >= 1000) {
        return '+' + (number / 1000).toFixed(1) + 'K de descargas';
    } else {
        return '+' + number.toString();
    }
};

const Ratings = () => {
    const { t } = useTranslation();
    const initialDownloads = 20000000; // 20 million initial downloads
    const downloadsPerHour = 1000; // Number of downloads added per hour
    const downloadsPerSecond = downloadsPerHour / 3600; // Number of downloads added per second
    const startDate = new Date('2024-06-11T00:00:00Z'); // Start date for counting downloads

    const calculateDownloads = () => {
        const now = new Date();
        const secondsPassed = Math.floor((now - startDate) / 1000);
        return initialDownloads + (secondsPassed * downloadsPerSecond);
    };

    const [currentDownloads, setCurrentDownloads] = useState(calculateDownloads);
    const value = 4.5;

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentDownloads(calculateDownloads());
        }, 1000); // Update every second to keep it real-time

        return () => clearInterval(interval);
    }, []);

    return (
        <Container style={{ textAlign: "center" }}>
            <div className="aparecer">
                <Typography
                    variant="h4"
                    align="center"
                    gutterBottom
                    style={{ fontFamily: "'Open Sans', sans-serif" }}
                >
                    {t("ratings1")}
                </Typography>
                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={4} md={3}>
                        <Rating
                            name="read-only"
                            value={value}
                            readOnly
                            precision={0.5}
                            style={{ marginTop: window.innerWidth < 600 ? "30px" : "0px" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <Typography
                            variant="h6"
                            style={{
                                marginTop: window.innerWidth < 600 ? "30px" : "0px",
                                fontFamily: "'Open Sans', sans-serif",
                            }}
                        >
                            <CountUp
                                start={currentDownloads}
                                end={currentDownloads}
                                duration={1}
                                separator=","
                                formattingFn={(value) => `${value} ${t("ratings2")}`}
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} style={{ textAlign: "center" }}>
                        <img
                            src={googleplaypass}
                            alt="Logo"
                            style={{
                                maxWidth: "50%",
                                height: "auto",
                                objectFit: "contain",
                                maxHeight: "150px",
                                marginBottom: "20px",
                            }}
                        />
                    </Grid>
                </Grid>
                <Typography
                    variant="h5"
                    style={{
                        marginTop: "20px",
                        marginBottom: "10px",
                        fontFamily: "'Open Sans', sans-serif",
                    }}
                >
                    {t("all_our_games")}
                </Typography>
                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Button
                            component={Link}
                            to="https://play.google.com/store/apps/dev?id=8933575792593715746"
                            target="_blank"
                            variant="outlined"
                            fullWidth
                            startIcon={<AndroidIcon />}
                            endIcon={<AndroidIcon />}
                            sx={{
                                marginTop: "15px",
                                marginBottom: "15px",
                                color: "white",
                                textTransform: "uppercase",
                                fontFamily: "'Bebas Neue', sans-serif",
                                fontSize: {
                                    xs: "1rem",
                                    sm: "1.25rem",
                                    md: "1.5rem",
                                    lg: "1.75rem",
                                },
                                padding: {
                                    xs: "8px 24px",
                                    sm: "10px 30px",
                                    md: "12px 36px",
                                    lg: "14px 42px",
                                },
                                borderColor: "white",
                                "&:hover": {
                                    color: "#232B7A",
                                    borderColor: "#232B7A",
                                },
                            }}
                        >
                            {t("google_play_games")}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Button
                            component={Link}
                            to="https://itunes.apple.com/developer/quarzo-apps/id1441517949"
                            target="_blank"
                            variant="outlined"
                            fullWidth
                            startIcon={<AppleIcon />}
                            endIcon={<AppleIcon />}
                            sx={{
                                marginTop: "15px",
                                marginBottom: "15px",
                                color: "white",
                                textTransform: "uppercase",
                                fontFamily: "'Bebas Neue', sans-serif",
                                fontSize: {
                                    xs: "1rem",
                                    sm: "1.25rem",
                                    md: "1.5rem",
                                    lg: "1.75rem",
                                },
                                padding: {
                                    xs: "8px 24px",
                                    sm: "10px 30px",
                                    md: "12px 36px",
                                    lg: "14px 42px",
                                },
                                borderColor: "white",
                                "&:hover": {
                                    color: "#232B7A",
                                    borderColor: "#232B7A",
                                },
                            }}
                        >
                            {t("apple_store_games")}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
};

export default Ratings;
