import React, { useState, useEffect } from "react";
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/FilterAlt';
import Divider from '@mui/material/Divider';
import GooglePlayIcon from '@mui/icons-material/Shop';
import AppStoreIcon from '@mui/icons-material/Apple';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import juegosEs from '../json/juegos_es.json';
import juegosEn from '../json/juegos_en.json';
import Aos from 'aos';
import 'aos/dist/aos.css';

const importAll = (r) => {
    let images = {};
    r.keys().map((item) => { images[item.replace('./', '')] = r(item); });
    return images;
}

const images = importAll(require.context('../img', false, /\.(png|jpe?g|svg)$/));

const Juegos = () => {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const [juegos, setJuegos] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedJuego, setSelectedJuego] = useState(null);
    const [filterTags, setFilterTags] = useState([]);
    const [drawerOpen, setDrawerOpen] = useState(false);

    useEffect(() => {
        const language = i18n.language === 'es' ? juegosEs : juegosEn;
        setJuegos(language);
    }, [i18n.language]);

    useEffect(() => {
        Aos.init();
    }, []);

    const handleOpen = (juego) => {
        setSelectedJuego(juego);
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
        setSelectedJuego(null);
    };

    const handleTagChange = (tag) => {
        setFilterTags((prevTags) =>
            prevTags.includes(tag) ? prevTags.filter((t) => t !== tag) : [...prevTags, tag]
        );
    };

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const filteredJuegos = juegos.filter((juego) =>
        filterTags.length === 0 || juego.tags.some((tag) => filterTags.includes(tag))
    );

    const uniqueTags = [...new Set(juegos.flatMap(juego => juego.tags))];

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        maxWidth: '600px',
        height: '80%',
        maxHeight: '800px',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        borderRadius: '10px',
        backgroundColor: '#08171D',
        color: 'white',
        overflowY: 'auto',
    };

    const closeButtonStyle = {
        position: 'absolute',
        right: '10px',
        top: '10px',
        color: 'white',
    };

    const contentStyle = {
        mt: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    };

    const drawerWidth = 250;

    const drawer = (
        <Box
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                bgcolor: '#10242c',
                color: 'white',
                position: 'fixed',
                top: 0,
                bottom: 0,
                left: 0,
                overflowY: 'auto',
                zIndex: 1000,
            }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <Box sx={{ display: 'flex', marginTop: '12px', justifyContent: 'space-between', alignItems: 'center', mb: 2, px: 2 }}>
                <div></div>
                <Typography variant="h6" fontFamily="'Bebas Neue', sans-serif">Filtros</Typography>
                <IconButton onClick={toggleDrawer(false)} sx={{ color: 'white' }}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Box sx={{ height: '2px', backgroundColor: '#232B7A', mb: 2 }} />
            <Box sx={{ flex: 1, overflowY: 'auto', px: 2 }}>
                {uniqueTags.map(tag => (
                    <Box key={tag} sx={{ width: '100%', display: 'block' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={filterTags.includes(tag)}
                                    onChange={() => handleTagChange(tag)}
                                    name={tag}
                                    color="primary"
                                    sx={{ color: "#232B7A" }}
                                />
                            }
                            label={<Typography variant="h6" sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>{tag}</Typography>}
                        />
                    </Box>
                ))}
            </Box>
        </Box>
    );

    return (
        <Container sx={{ pl: { xs: 0, sm: 2 }, pr: { xs: 0, sm: 2 } }}>
            <div className="aparecer">
                <Typography
                    variant="h2"
                    component="h1"
                    align="center"
                    fontFamily="'Bebas Neue', sans-serif"
                    color={"white"}
                    gutterBottom
                    sx={{
                        fontSize: { xs: '5rem', sm: '5rem', md: '8rem' },
                        mt: 4,
                        mb: 4,
                        margin: 0,
                        letterSpacing: 2,
                        textShadow: '2px 2px 8px rgba(0, 0, 0, 0.5)',
                    }}
                >
                    {t('games')}
                </Typography>
                <Divider sx={{ backgroundColor: 'white', height: 2, width: { xs: '80%', sm: '70%', md: '65%', lg: '75%', }, margin: '0 auto 20px' }} />
                <IconButton
                    edge="start"
                    color="white"
                    aria-label="menu"
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        mb: 2,
                        ml: 'auto',
                        mr: '10px'
                    }}
                    onClick={toggleDrawer(true)}
                >
                    <MenuIcon sx={{ color: 'white' }} />
                </IconButton>
                <Drawer
                    anchor="top"
                    open={drawerOpen}
                    onClose={toggleDrawer(false)}
                    sx={{ display: { xs: 'block', sm: 'none' } }}
                >
                    {drawer}
                </Drawer>
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ display: { xs: 'none', sm: 'block' }, width: 150, p: 2 }}>
                        <Typography variant="h6" fontFamily="'Bebas Neue', sans-serif" color='white' sx={{ textAlign: 'center' }}>
                            Filtros
                        </Typography>
                        <Box sx={{ height: '2px', backgroundColor: '#232B7A', my: 2 }} />
                        {uniqueTags.map(tag => (
                            <FormControlLabel
                                key={tag}
                                control={
                                    <Checkbox
                                        checked={filterTags.includes(tag)}
                                        onChange={() => handleTagChange(tag)}
                                        name={tag}
                                        color="primary"
                                        style={{ color: "#232B7A" }}
                                    />
                                }
                                label={
                                    <Typography variant="h6" color="white" fontFamily="'Open Sans', sans-serif" sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                                        {tag}
                                    </Typography>
                                }
                            />
                        ))}
                    </Box>

                    <Divider orientation="vertical" color="#232B7A" flexItem sx={{ display: { xs: 'none', sm: 'block' }, marginLeft: '10px' }} />
                    <Box sx={{ flex: 1, p: 2 }}>
                        <Grid container spacing={3} >
                            {filteredJuegos.map((juego) => (
                                <Grid item key={juego.nombre} xs={12} sm={6} md={3}>
                                    <Card
                                        onClick={() => handleOpen(juego)}
                                        sx={{
                                            height: '100%',
                                            backgroundColor: '#10242c',
                                            borderRadius: '16px',
                                            textAlign: 'center',
                                            transition: 'background-color 0.3s',
                                            '&:hover': {
                                                cursor: 'pointer',
                                                backgroundColor: '#232B7A',
                                            },
                                        }}
                                    >
                                        <CardMedia
                                            component="img"
                                            height="200"
                                            image={images[juego.foto]}
                                            alt={juego.nombre}
                                        />
                                        <CardContent
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography
                                                gutterBottom
                                                variant="h5"
                                                fontFamily="'Bebas Neue', sans-serif"
                                                component="div"
                                                sx={{ color: 'white', transition: 'color 0.3s' }}
                                            >
                                                {juego.nombre}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>
                <Modal
                    open={modalOpen}
                    onClose={handleClose}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Box sx={modalStyle}>
                        <IconButton sx={closeButtonStyle} onClick={handleClose}>
                            <CloseIcon sx={{ color: 'white' }} />
                        </IconButton>
                        {selectedJuego && (
                            <Box sx={contentStyle}>
                                <Typography
                                    id="modal-title"
                                    variant="h4"
                                    fontFamily="'Bebas Neue', sans-serif"
                                    component="h2"
                                    align="center"
                                >
                                    {selectedJuego.nombre}
                                </Typography>
                                <Box display="flex" justifyContent="center" my={2}>
                                    <img
                                        src={images[selectedJuego.foto]}
                                        alt={selectedJuego.nombre}
                                        style={{
                                            width: '100%',
                                            height: 'auto',
                                            maxWidth: '300px',
                                        }}
                                    />
                                </Box>
                                <Typography
                                    id="modal-description"
                                    fontFamily="'Open Sans', sans-serif"
                                    sx={{ mt: 2 }}
                                    align="center"
                                >
                                    {selectedJuego.descripcion}
                                </Typography>
                                <Box
                                    display="flex"
                                    justifyContent="space-around"
                                    flexWrap="wrap"
                                    mt={2}
                                >
                                    <Typography fontFamily="'Open Sans', sans-serif" sx={{ m: 1 }}>
                                        <strong>Fecha de lanzamiento:</strong>{" "}
                                        <span style={{ color: '#FFC96F' }}>{selectedJuego.fechaLanzamiento}</span>
                                    </Typography>
                                    <Typography fontFamily="'Open Sans', sans-serif" sx={{ m: 1 }}>
                                        <strong>Categoría:</strong>{" "}
                                        <span style={{ color: '#FFC96F' }}>{selectedJuego.categoria}</span>
                                    </Typography>
                                    <Typography fontFamily="'Open Sans', sans-serif" sx={{ m: 1 }}>
                                        <strong>Número de descargas:</strong>{" "}
                                        <span style={{ color: '#FFC96F' }}>{selectedJuego.numeroDescargas}</span>
                                    </Typography>
                                    <Typography fontFamily="'Open Sans', sans-serif" sx={{ m: 1 }}>
                                        <strong>Puntuación:</strong>{" "}
                                        <span style={{ color: '#FFC96F' }}>{selectedJuego.puntuacion}</span>
                                    </Typography>
                                </Box>
                                <Box
                                    mt={2}
                                    display="flex"
                                    justifyContent="center"
                                    flexWrap="wrap"
                                >
                                    {selectedJuego.googlePlay && (
                                        <Typography sx={{ m: 1 }}>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    backgroundColor: 'white',
                                                    color: 'black',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                                href={selectedJuego.googlePlay}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <GooglePlayIcon sx={{ mr: 1 }} /> Google Play
                                            </Button>
                                        </Typography>
                                    )}
                                    {selectedJuego.appleStore && (
                                        <Typography sx={{ m: 1 }}>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    backgroundColor: 'white',
                                                    color: 'black',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                                href={selectedJuego.appleStore}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <AppStoreIcon sx={{ mr: 1 }} /> App Store
                                            </Button>
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Modal>
            </div>
        </Container>
    );
};

export default Juegos;
