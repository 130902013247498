import React from "react";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import logointro from '../img/logointro.png';
import { useTranslation } from 'react-i18next';

const Intro = () => {
    const { t } = useTranslation();

    return (
        <div className="Intro">
            <Container maxWidth="lg">
                <div className="aparecer">
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item xs={12} lg={6} style={{ marginBottom: '50px', marginTop: '50px', paddingLeft: '20px', paddingRight: '50px' }}>
                            <Grid container direction="column" spacing={2} alignItems="left">
                                <Grid item>
                                    <Typography variant="h1" style={{
                                        position: 'relative',
                                        zIndex: '1',
                                        color: 'white',
                                        textAlign: 'left',
                                        fontSize: 'clamp(3rem, 12vw, 7rem)',
                                        fontFamily: "'Open Sans', sans-serif",
                                        width: '100%',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        textShadow: '2px 2px 8px rgba(0, 0, 0, 0.5)',
                                    }}>
                                        Quarzo Apps
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h5" style={{
                                        position: 'relative',
                                        zIndex: '1',
                                        color: 'white',
                                        textAlign: 'left',
                                        fontSize: 'clamp(1.5rem, 6vw, 3rem)',
                                        fontFamily: "'Open Sans', sans-serif",
                                        marginBottom: '30px'
                                    }}>
                                        {t('welcome')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={6} style={{ marginTop: '50px' }}>
                            <div style={{ display: 'flex', justifyContent: 'right', position: 'relative', zIndex: '0' }}>
                                <img src={logointro} alt="Logo" style={{ maxWidth: '100%', height: 'auto', marginBottom: '80px' }} />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </div>
    );
}

export default Intro;
