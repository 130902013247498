// i18n.js (o cualquier nombre que elijas)
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationEN from './translations/en/translation.json'; // Archivo de traducción para inglés
import translationES from './translations/es/translation.json'; // Archivo de traducción para español

i18n
  .use(LanguageDetector) // Utiliza el detector de idioma del navegador
  .use(initReactI18next) // Inicializa react-i18next
  .init({
    interpolation: {
      escapeValue: false, // Permitir HTML en las traducciones
    },
    resources: {
      en: {
        translation: translationEN, // Traducciones para inglés
      },
      es: {
        translation: translationES, // Traducciones para español
      },
    },
    fallbackLng: 'en', // Idioma por defecto si el idioma detectado no está disponible
    debug: true, // Habilita la depuración en la consola
    interpolation: {
      escapeValue: false, // No necesitas escapar los valores traducidos
    },
  });

export default i18n;