import React from 'react';
import { useCookies } from '../CookiesContext';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Typography, styled, Slide, Box } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import CookieIcon from '@mui/icons-material/Cookie';
import { useTranslation } from 'react-i18next';

// Estilos personalizados para el modal
const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        backgroundColor: '#0A1A1B',
        margin: 0, // Asegura que no haya margen extra
        position: 'fixed',
        bottom: 0,
        borderRadius: theme.shape.borderRadius, // Redondea todas las esquinas
    },
    '& .MuiDialogTitle-root': {
        backgroundColor: '#232B7A',
        color: '#ffffff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(2),
    },
    '& .MuiDialogContent-root': {
        backgroundColor: '#0A1A1B',
        color: 'white',
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        backgroundColor: '#0A1A1B',
        padding: theme.spacing(2),
    },
    '& a': {
        color: '#ff4081',
        textDecoration: 'underline',
    },
}));

const CookieModal = () => {
    const { t } = useTranslation();
    const { setCookie } = useCookies();
    const [open, setOpen] = React.useState(true);

    const handleAcceptCookies = () => {
        setCookie('firstVisit', 'true', { expires: 7 });
        setCookie('cookiesAccepted', 'true', { expires: 365 });
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end', // Para alinearlo en la parte inferior
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 1300, // Para asegurarse de que esté sobre otros elementos
                pointerEvents: 'none', // Permite hacer clic en el modal, pero no en el fondo
            }}
        >
            <StyledDialog
                open={open}
                onClose={(e, reason) => {
                    if (reason !== 'backdropClick') {
                        handleClose();
                    }
                }}
                TransitionComponent={(props) => <Slide {...props} direction="up" />}
                disableEscapeKeyDown // Deshabilita el cierre al presionar la tecla Escape
                BackdropProps={{ invisible: true }} // Hace el backdrop no interactivo
                sx={{ pointerEvents: 'auto' }} // Permite hacer clic en el modal
            >
                <DialogTitle>
                    <CookieIcon sx={{ fontSize: 24 }} />
                    <Typography variant="h6" sx={{ marginLeft: 2 }}>{t('cookies1')}</Typography>
                    <IconButton onClick={handleClose} sx={{ color: '#ffffff' }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        {t('cookies2')}
                    </Typography>
                    <Typography gutterBottom>
                        <span dangerouslySetInnerHTML={{ __html: t('cookies3') }} />
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAcceptCookies} variant="contained" sx={{ bgcolor: 'success.main', color: 'white' }}>
                        {t('cookies4')}
                    </Button>
                    <Button onClick={handleClose} variant="contained" sx={{ bgcolor: 'error.main', color: 'white' }}>
                        {t('cookies5')}
                    </Button>
                </DialogActions>
            </StyledDialog>
        </Box>
    );
};

export default CookieModal;
