import React from "react";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { List, ListItem, Link } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Typography from '@mui/material/Typography';

const PrivacidadEn = () => {
    return (
        <div style={{ backgroundColor: '#08171D', padding: '20px' }}>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12} md={10} lg={8} sx={{ paddingX: 3 }}>
                    <Typography variant="h1" align="center" gutterBottom sx={{ fontSize: { xs: '2.5rem', md: '3.5rem', lg: '4rem' }, fontWeight: 'bold', color: '#fff', marginTop: '30px' }} >
                        Quarzo Apps <br /> Privacy policy
                        <Grid item xs={12}>
                            <Divider variant="middle" sx={{ backgroundColor: '#232B7A', height: 2, borderWidth: 2, marginTop: '15px' }} />
                        </Grid>
                    </Typography>
                    <Typography variant="body1" align="center" paragraph sx={{ fontSize: { xs: '1rem', md: '1.25rem', lg: '1.5rem' }, color: '#fff', marginTop: '30px' }} >
                        "Quarzo Aplicaciones y Juegos SL" ("Quarzo Apps") builds in analytics in our games to enable us to improve them, to give the user the best possible game experience. This picks up anonymous data that simply allows us to find out what users do and do not like. Quarzo Apps fully respects the user's privacy and never collects information that could be directly linked to the user. Quarzo Apps also displays advertisements to fund the creation of these games. This is all governed by the following Privacy policy:
                        <br /><br />PLEASE NOTE THAT BY INSTALLING, USING OR OTHERWISE ACCESSING ANY QUARZO APPS MOBILE APPLICATION, YOU ARE AGREEING TO THIS PRIVACY POLICY. IF YOU DO NOT AGREE TO THIS PRIVACY POLICY, PLEASE DO NOT INSTALL, USE OR OTHERWISE ACCESS OUR APPLICATIONS. We reserve the right to modify this Privacy Policy at reasonable times, so please review it frequently. Your continued use of our applications will signify your acceptance of the changes to this Privacy Policy.                    </Typography>
                    <Typography variant="h1" align="center" gutterBottom sx={{ fontSize: { xs: '2.5rem', md: '3.5rem', lg: '4rem' }, fontWeight: 'bold', color: '#fff', marginTop: '30px' }} >
                        Online Accounts and Personal Information
                        <Grid item xs={12}>
                            <Divider variant="middle" sx={{ backgroundColor: '#232B7A', height: 2, borderWidth: 2, marginTop: '15px' }} />
                        </Grid>
                    </Typography>
                    <Typography variant="body1" align="center" paragraph sx={{ fontSize: { xs: '1rem', md: '1.25rem', lg: '1.5rem' }, color: '#fff', marginTop: '30px' }} >
                        Some apps or games may need to register an account to play. Nickname, password, avatar image, email, country or other personal information will need to be provided. We will not sell, rent or transfer your Personal Information to third parties without your prior consent.
                        Email address will never be public to other users. Will only be used to recover a "lost" account by sending a new password, or sometimes to contact or inform users about important notices.                    </Typography>
                    <Typography variant="h1" align="center" gutterBottom sx={{ fontSize: { xs: '2.5rem', md: '3.5rem', lg: '4rem' }, fontWeight: 'bold', color: '#fff', marginTop: '30px' }} >
                        Advertising
                        <Grid item xs={12}>
                            <Divider variant="middle" sx={{ backgroundColor: '#232B7A', height: 2, borderWidth: 2, marginTop: '15px' }} />
                        </Grid>
                    </Typography>
                    <Typography variant="body1" align="center" paragraph sx={{ fontSize: { xs: '1rem', md: '1.25rem', lg: '1.5rem' }, color: '#fff', marginTop: '30px' }} >
                        Some of our Apps and Games may show ads. This are necessary to maintain the development of our company. You can do an in-app purchase that removes ads lifetime. For more information about personalized ads check this link: <a href="https://support.google.com/My-Ad-Center-Help/answer/12155764?hl=es&visit_id=638505857661831699-2681632183&rd=1" target="_blank" rel="noopener noreferrer">Google support about Ads</a>
                    </Typography>
                    <Typography variant="h1" align="center" gutterBottom sx={{ fontSize: { xs: '2.5rem', md: '3.5rem', lg: '4rem' }, fontWeight: 'bold', color: '#fff', marginTop: '30px' }} >
                        Anonymous Data
                        <Grid item xs={12}>
                            <Divider variant="middle" sx={{ backgroundColor: '#232B7A', height: 2, borderWidth: 2, marginTop: '15px' }} />
                        </Grid>
                    </Typography>
                    <Typography variant="body1" align="center" paragraph sx={{ fontSize: { xs: '1rem', md: '1.25rem', lg: '1.5rem' }, color: '#fff', marginTop: '30px' }} >
                        Advertisers who provide adverts to our mobile applications may access your unique device identifier and use this to target messages to you. <br /><br />Quarzo Apps may collect and use anonymous data. We cannot identify you from this data. <br /><br />Quarzo Apps may use the collected data in order to analyze how our applications are used, and use the information to develop and manage our applications. Quarzo Apps reserves the right to use the collected anonymous data ourselves or disclose it to our partners.<br /><br />Quarzo Apps may use the service provided by Google Analytics and Google Firebase. This are analysis services provided by Google Inc. (“Google”). Google utilizes the Data collected to track and examine the use of this Application, to prepare reports on its activities and share them with other Google services. Google may use the Data collected to contextualize and personalize the ads of its own advertising network. Personal Data collected: Cookie and Usage data.
                    </Typography>
                    <Typography variant="h1" align="center" gutterBottom sx={{ fontSize: { xs: '2.5rem', md: '3.5rem', lg: '4rem' }, fontWeight: 'bold', color: '#fff', marginTop: '30px' }} >
                        Third Party Terms and Conditions
                        <Grid item xs={12}>
                            <Divider variant="middle" sx={{ backgroundColor: '#232B7A', height: 2, borderWidth: 2, marginTop: '15px' }} />
                        </Grid>
                    </Typography>
                    <Typography variant="body1" align="center" paragraph sx={{ fontSize: { xs: '1rem', md: '1.25rem', lg: '1.5rem' }, color: '#fff', marginTop: '30px' }} >
                        Please note that certain third parties, including but not limited to application stores, may be needed in order for you to access our mobile applications. These third parties have their own privacy policies which Quarzo Apps cannot control. You agree that Quarzo Apps is not liable for their use of your personal information.                    </Typography>
                    <Typography variant="h1" align="center" gutterBottom sx={{ fontSize: { xs: '2.5rem', md: '3.5rem', lg: '4rem' }, fontWeight: 'bold', color: '#fff', marginTop: '30px' }} >
                        Links to Third Party Sites or Games
                        <Grid item xs={12}>
                            <Divider variant="middle" sx={{ backgroundColor: '#232B7A', height: 2, borderWidth: 2, marginTop: '15px' }} />
                        </Grid>
                    </Typography>
                    <Typography variant="body1" align="center" paragraph sx={{ fontSize: { xs: '1rem', md: '1.25rem', lg: '1.5rem' }, color: '#fff', marginTop: '30px' }}>
                        Our mobile applications are supported by advertisements, which provide access to third party sites or games.<br /><br />
                        Our mobile applications provide access to social networking, communication and search features.<br /><br />
                        Please be aware that, if you use any of these links you will leave our app and the page you go to will not be governed by our privacy policy. We cannot be responsible for the privacy of any information you provide whilst outside of our app.<br /><br />
                        We currently work with the following advertising networks and analytics companies:
                    </Typography>

                    <Container maxWidth="lg" sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                        <Box textAlign="center">
                            <List sx={{ listStyleType: 'disc', textAlign: 'center', padding: 0, fontSize: { xs: '1rem', md: '1.25rem', lg: '1.5rem' }, color: '#fff' }}>
                                <ListItem>
                                    - Google AdMob (<Link href="https://policies.google.com/technologies/partner-sites">Privacy Policy</Link>)
                                </ListItem>
                                <ListItem>
                                    - Google Analytics (<Link href="https://support.google.com/analytics/answer/7318509?hl=en/">Privacy Policy</Link>)
                                </ListItem>
                                <ListItem>
                                    - Chartboost (<Link href="https://docs.chartboost.com/en/legal/privacy-policy/">Privacy Policy</Link>)
                                </ListItem>
                                <ListItem>
                                    - Applovin (<Link href="https://www.applovin.com/privacy/">Privacy Policy</Link>)
                                </ListItem>
                                <ListItem>
                                    - Unity Ads (<Link href="https://unity.com/es/legal/privacy-policy">Privacy Policy</Link>)
                                </ListItem>
                                <ListItem>
                                    - Facebook Audience Network (<Link href="https://transparency.meta.com/es-es/policies/ad-standards/?source=https%3A%2F%2Fwww.facebook.com%2Fpolicies_center%2Fads">Privacy Policy</Link>)
                                </ListItem>
                                <ListItem>
                                    - Firebase (<Link href="https://firebase.google.com/support/privacy?hl=es">Política de privacidad</Link>)
                                </ListItem>
                            </List>
                        </Box>
                    </Container>
                    <Typography variant="body1" align="center" paragraph sx={{ fontSize: { xs: '1rem', md: '1.25rem', lg: '1.5rem' }, color: '#fff', marginTop: '30px' }}>
                    Please refer to the privacy policies of these companies for information about their privacy practices.
                    </Typography>
                    <Typography variant="body1" align="center" paragraph sx={{ fontSize: { xs: '1rem', md: '1.25rem', lg: '1.5rem' }, fontWeight: 'bold', color: '#fff', marginTop: '30px' }}>
                        Version 1.0 - Document created 23 Ene. 2015.<br />
                        Version 1.1 - Document updated 29 Sep. 2015.<br />
                        Version 1.2 - Document updated 06 Abr. 2017.<br />
                        Version 1.3 - Document updated 15 Mar. 2018.<br />
                        Version 1.4 - Document updated 21 Jun. 2018.<br />
                        Version 1.5 - Document updated 06 May. 2024 (included GDPR changes).
                    </Typography>
                    <Typography variant="body1" align="center" paragraph sx={{ fontSize: { xs: '1rem', md: '1.25rem', lg: '1.5rem' }, fontWeight: 'bold', color: '#fff', marginTop: '30px' }}>
                        © Quarzo Apps<br />
                        Quarzo Aplicaciones y Juegos SL<br />
                        CIF: B87302808<br />
                        Jupiter, 16<br />
                        28229 Madrid<br />
                        SPAIN<br /><br />
                        Contact: hola@quarzoapps.com | @quarzoapps
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
}

export default PrivacidadEn;