import React from "react";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { List, ListItem, Link } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

const PrivacidadEs = () => {
    const { t } = useTranslation();
    
    return (
        <div style={{ backgroundColor: '#08171D', padding: '20px'}}>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12} md={10} lg={8} sx={{ paddingX: 3 }}>
                    <Typography variant="h1" align="center" gutterBottom sx={{ fontSize: { xs: '2.5rem', md: '3.5rem', lg: '4rem' }, fontWeight: 'bold', color: '#fff', marginTop: '30px' }} >
                    <span dangerouslySetInnerHTML={{ __html: t('title') }} />
                        <Grid item xs={12}>
                            <Divider variant="middle" sx={{ backgroundColor: '#232B7A', height: 2, borderWidth: 2, marginTop: '15px' }} />
                        </Grid>
                    </Typography>
                    <Typography variant="body1" align="center" paragraph sx={{ fontSize: { xs: '1rem', md: '1.25rem', lg: '1.5rem' }, color: '#fff', marginTop: '30px' }} >
                        <span dangerouslySetInnerHTML={{ __html: t('intro') }} />
                    </Typography>
                    <Typography variant="h1" align="center" gutterBottom sx={{ fontSize: { xs: '2.5rem', md: '3.5rem', lg: '4rem' }, fontWeight: 'bold', color: '#fff', marginTop: '30px' }} >
                        <span dangerouslySetInnerHTML={{ __html: t('accountsTitle') }} />
                        <Grid item xs={12}>
                            <Divider variant="middle" sx={{ backgroundColor: '#232B7A', height: 2, borderWidth: 2, marginTop: '15px' }} />
                        </Grid>
                    </Typography>
                    <Typography variant="body1" align="center" paragraph sx={{ fontSize: { xs: '1rem', md: '1.25rem', lg: '1.5rem' }, color: '#fff', marginTop: '30px' }} >
                        <span dangerouslySetInnerHTML={{ __html: t('accounts') }} />
                    </Typography>
                    <Typography variant="h1" align="center" gutterBottom sx={{ fontSize: { xs: '2.5rem', md: '3.5rem', lg: '4rem' }, fontWeight: 'bold', color: '#fff', marginTop: '30px' }} >
                        <span dangerouslySetInnerHTML={{ __html: t('adsTitle') }} />
                        <Grid item xs={12}>
                            <Divider variant="middle" sx={{ backgroundColor: '#232B7A', height: 2, borderWidth: 2, marginTop: '15px' }} />
                        </Grid>
                    </Typography>
                    <Typography variant="body1" align="center" paragraph sx={{ fontSize: { xs: '1rem', md: '1.25rem', lg: '1.5rem' }, color: '#fff', marginTop: '30px' }} >
                        <span dangerouslySetInnerHTML={{ __html: t('ads') }} />
                    </Typography>
                    <Typography variant="h1" align="center" gutterBottom sx={{ fontSize: { xs: '2.5rem', md: '3.5rem', lg: '4rem' }, fontWeight: 'bold', color: '#fff', marginTop: '30px' }} >
                        <span dangerouslySetInnerHTML={{ __html: t('anonymousDataTitle') }} />
                        <Grid item xs={12}>
                            <Divider variant="middle" sx={{ backgroundColor: '#232B7A', height: 2, borderWidth: 2, marginTop: '15px' }} />
                        </Grid>
                    </Typography>
                    <Typography variant="body1" align="center" paragraph sx={{ fontSize: { xs: '1rem', md: '1.25rem', lg: '1.5rem' }, color: '#fff', marginTop: '30px' }} >
                        <span dangerouslySetInnerHTML={{ __html: t('anonymousData') }} />
                    </Typography>
                    <Typography variant="h1" align="center" gutterBottom sx={{ fontSize: { xs: '2.5rem', md: '3.5rem', lg: '4rem' }, fontWeight: 'bold', color: '#fff', marginTop: '30px' }} >
                        <span dangerouslySetInnerHTML={{ __html: t('thirdPartiesTitle') }} />
                        <Grid item xs={12}>
                            <Divider variant="middle" sx={{ backgroundColor: '#232B7A', height: 2, borderWidth: 2, marginTop: '15px' }} />
                        </Grid>
                    </Typography>
                    <Typography variant="body1" align="center" paragraph sx={{ fontSize: { xs: '1rem', md: '1.25rem', lg: '1.5rem' }, color: '#fff', marginTop: '30px' }} >
                        <span dangerouslySetInnerHTML={{ __html: t('thirdParties') }} />
                    </Typography>
                    <Typography variant="h1" align="center" gutterBottom sx={{ fontSize: { xs: '2.5rem', md: '3.5rem', lg: '4rem' }, fontWeight: 'bold', color: '#fff', marginTop: '30px' }} >
                        <span dangerouslySetInnerHTML={{ __html: t('thirdPartyLinksTitle') }} />
                        <Grid item xs={12}>
                            <Divider variant="middle" sx={{ backgroundColor: '#232B7A', height: 2, borderWidth: 2, marginTop: '15px' }} />
                        </Grid>
                    </Typography>
                    <Typography variant="body1" align="center" paragraph sx={{ fontSize: { xs: '1rem', md: '1.25rem', lg: '1.5rem' }, color: '#fff', marginTop: '30px' }}>
                        <span dangerouslySetInnerHTML={{ __html: t('thirdPartyLinks') }} />
                    </Typography>
                    <Container maxWidth="lg" sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                        <Box textAlign="center">
                            <List sx={{ listStyleType: 'disc', textAlign: 'center', padding: 0, fontSize: { xs: '1rem', md: '1.25rem', lg: '1.5rem' }, color: '#fff' }}>
                                <ListItem>
                                    - Google AdMob (<Link href="https://policies.google.com/technologies/partner-sites">{t('privacyPolicy')}</Link>)
                                </ListItem>
                                <ListItem>
                                    - Google Analytics (<Link href="https://support.google.com/analytics/answer/7318509?hl=en/">{t('privacyPolicy')}</Link>)
                                </ListItem>
                                <ListItem>
                                    - Chartboost (<Link href="https://docs.chartboost.com/en/legal/privacy-policy/">{t('privacyPolicy')}</Link>)
                                </ListItem>
                                <ListItem>
                                    - Applovin (<Link href="https://www.applovin.com/privacy/">{t('privacyPolicy')}</Link>)
                                </ListItem>
                                <ListItem>
                                    - Unity Ads (<Link href="https://unity.com/es/legal/privacy-policy">{t('privacyPolicy')}</Link>)
                                </ListItem>
                                <ListItem>
                                    - Facebook Audience Network (<Link href="https://transparency.meta.com/es-es/policies/ad-standards/?source=https%3A%2F%2Fwww.facebook.com%2Fpolicies_center%2Fads">{t('privacyPolicy')}</Link>)
                                </ListItem>
                                <ListItem>
                                    - Firebase (<Link href="https://firebase.google.com/support/privacy?hl=es">{t('privacyPolicy')}</Link>)
                                </ListItem>
                            </List>
                        </Box>
                    </Container>
                    <Typography variant="body1" align="center" paragraph sx={{ fontSize: { xs: '1rem', md: '1.25rem', lg: '1.5rem' }, color: '#fff', marginTop: '30px' }}>
                        <span dangerouslySetInnerHTML={{ __html: t('referPolicies') }} />
                    </Typography>
                    <Typography variant="h1" align="center" gutterBottom sx={{ fontSize: { xs: '2.5rem', md: '3.5rem', lg: '4rem' }, fontWeight: 'bold', color: '#fff', marginTop: '30px' }} >
                        <span dangerouslySetInnerHTML={{ __html: t('cookiesTitle') }} />
                        <Grid item xs={12}>
                            <Divider variant="middle" sx={{ backgroundColor: '#232B7A', height: 2, borderWidth: 2, marginTop: '15px' }} />
                        </Grid>
                    </Typography>
                    <Typography variant="body1" align="center" paragraph sx={{ fontSize: { xs: '1rem', md: '1.25rem', lg: '1.5rem' }, color: '#fff', marginTop: '30px' }} >
                        <span dangerouslySetInnerHTML={{ __html: t('cookies') }} />
                    </Typography>
                    <Divider variant="middle" sx={{ backgroundColor: '#232B7A', height: 2, borderWidth: 2, marginTop: '15px' }} />
                    <Typography variant="body1" align="center" paragraph sx={{ fontSize: { xs: '1rem', md: '1.25rem', lg: '1.5rem' }, fontWeight: 'bold', color: '#fff', marginTop: '30px' }}>
                        <span dangerouslySetInnerHTML={{ __html: t('version') }} />
                    </Typography>
                    <Divider variant="middle" sx={{ backgroundColor: '#232B7A', height: 2, borderWidth: 2, marginTop: '15px' }} />
                    <Typography variant="body1" align="center" paragraph sx={{ fontSize: { xs: '1rem', md: '1.25rem', lg: '1.5rem' }, fontWeight: 'bold', color: '#fff', marginTop: '30px' }}>
                        <span dangerouslySetInnerHTML={{ __html: t('contact') }} />
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
}

export default PrivacidadEs;
