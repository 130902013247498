import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import XIcon from '@mui/icons-material/X';
import logoImg from '../img/logo.png';
import QuarzoLetters from '../img/QuarzoLetters.png';
import smalllogo from '../img/logo.png';
import Selectoridioma from './Selectoridioma';
import { useTranslation } from 'react-i18next';

const Navbar = () => {
  const { t } = useTranslation();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const location = useLocation();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleIconClickX = () => {
    const url = 'https://twitter.com/quarzoapps';
    window.location.href = url;
  };

  const handleLogoClick = () => {
    window.location.href = '/';
  };

  const pages = [
    { key: 'navbar1', path: '/' },
    { key: 'navbar2', path: '/games' },
    { key: 'navbar3', path: '/rankings' },
    { key: 'navbar4', path: '/aboutus' }
  ];

  const menuStyles = {
    '& .MuiPaper-root': {
      backgroundColor: '#08171D',
      color: 'white',
    },
  };

  return (
    <div className='Navbar'>
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-start', alignItems: 'center', flexGrow: 1 }}>
                <img src={smalllogo} alt="Logo" style={{ maxHeight: '90px', width: 'auto', cursor: 'pointer' }} onClick={handleLogoClick} />
                <IconButton
                  size="small"
                  aria-label="menu"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={menuStyles}
                >
                  {pages
                    .filter((page) => page.path !== location.pathname)
                    .map((page) => (
                      <MenuItem key={page.key} onClick={handleCloseNavMenu}>
                        <Typography textAlign="center" style={{ fontFamily: "'Open Sans', sans-serif" }}>
                          <Link to={page.path} style={{ color: 'white', textDecoration: 'none' }}>{t(page.key)}</Link>
                        </Typography>
                      </MenuItem>
                    ))}
                </Menu>
              </Box>
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <img src={logoImg} alt="Logo" style={{ maxHeight: '100px', width: 'auto', marginRight: '10px', cursor: 'pointer' }} onClick={handleLogoClick} />
                <img src={QuarzoLetters} alt="Logo" style={{ maxHeight: '110px', width: 'auto', marginRight: '10px', cursor: 'pointer' }} onClick={handleLogoClick} />
              </Box>
              <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end', alignItems: 'center', flexGrow: 1 }}>
                {pages
                  .filter((page) => page.path !== location.pathname)
                  .map((page) => (
                    <Button
                      key={page.key}
                      component={Link}
                      to={page.path}
                      sx={{ mx: 1, color: 'white', fontFamily: "'Open Sans', sans-serif" }}
                    >
                      {t(page.key)}
                    </Button>
                  ))}
              </Box>
              <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-end', alignItems: 'center' }}>
                <Tooltip title="X">
                  <IconButton onClick={handleIconClickX} sx={{ p: 0 }}>
                    <div style={{ color: 'white' }}>
                      <XIcon />
                    </div>
                  </IconButton>
                </Tooltip>
                <Box sx={{ ml: 1 }}>
                  <Selectoridioma />
                </Box>
              </Box>
              <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
                <Selectoridioma />
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

export default Navbar;