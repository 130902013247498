import React from "react";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t, i18n } = useTranslation();

    const privacyLink = i18n.language === 'es' ? '/#/privacy_es.html' : '/#/privacy_en.html';
    const privacyText = i18n.language === 'es' ? 'Versión en español' : 'English Version';
    const privacypolicy = i18n.language === 'es' ? 'Quarzo Política de privacidad' : 'Quarzo Privacy Policy';

    return (
        <Box
            component="footer"
            sx={{
                backgroundColor: '#31496B',
                color: '#fff',
                paddingY: 3,
                textAlign: 'center'
            }}
        >
            <Container>
                <Grid container justifyContent="space-around" alignItems="center">
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1">
                            Copyright © Quarzo Aplicaciones y Juegos SL. © 2013-2024.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1" style={{ display: 'flex', alignItems: 'center' }}>
                            {privacypolicy}
                            <ArrowForwardIcon style={{ marginLeft: '5px' }} />
                            <a href={privacyLink} style={{ textDecoration: 'none', color: 'green', display: 'flex', alignItems: 'center' }}>
                                <span style={{ marginLeft: '5px' }}>{privacyText}</span>
                            </a>
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default Footer;
