import React, { createContext, useContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const CookiesContext = createContext();

export const CookiesProvider = ({ children }) => {
  const [cookies, setCookies] = useState({});
  const [isFirstVisit, setIsFirstVisit] = useState(false);
  const [cookiesAccepted, setCookiesAccepted] = useState(false);

  useEffect(() => {
    const allCookies = Cookies.get();
    console.log("Cookies actuales:", allCookies);
    setCookies(allCookies);

    if (!allCookies.firstVisit && !allCookies.cookiesAccepted) {
      console.log("Primera visita detectada, mostrando modal de cookies");
      setIsFirstVisit(true);
    } else {
      console.log("No es la primera visita");
    }

    if (allCookies.cookiesAccepted) {
      setCookiesAccepted(true);
    }
  }, []);

  const setCookie = (name, value, options) => {
    Cookies.set(name, value, options);
    setCookies(prevCookies => ({ ...prevCookies, [name]: value }));
    if (name === 'cookiesAccepted') {
      setCookiesAccepted(true);
      setIsFirstVisit(false);
    }
  };

  const removeCookie = (name) => {
    Cookies.remove(name);
    setCookies(prevCookies => {
      const { [name]: _, ...rest } = prevCookies;
      return rest;
    });
  };

  return (
    <CookiesContext.Provider value={{ cookies, setCookie, removeCookie, isFirstVisit, cookiesAccepted }}>
      {children}
    </CookiesContext.Provider>
  );
};

export const useCookies = () => useContext(CookiesContext);